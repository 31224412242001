import * as React from "react";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./../../App.css";
import AddExpense from "./AddExpense";

export default function NewMemberListing({
  eventList = [],
  t,
  toggle,
  setSelectedUserId,
  setShowModel,
  setFindMemberID,
  showModel,
  resetField,
  setIsCollapsed,
}) {
  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();
  const [currentEventId] = useState("");
  const [addExpense, setAddExpense] = useState(false);

  const headCells = [
    {
      id: "firstName",
      label: `${t("First Name")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "lastName",
      label: `${t("Last Name")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "postcode",
      label: `${t("Postcode")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "mobileNumber",
      label: `${t("Phone No.")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "membershipCard",
      label: `${t("Member Card No.")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "action",
      label: `${t("Action")}`,
      disableSorting: true,
      align: "right",
    },
  ];

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function phoneNoMapping(value) {
    if (value) {
      return value.replace(/.(?=.{4})/g, "x");
    } else {
      return "";
    }
  }

  return (
    <div
      style={
        toggle
          ? { marginLeft: "2rem", overflowX: "auto", maxHeight: "500px" }
          : { overflowX: "auto", maxHeight: "500px" }
      }
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <div className="mt-3 w-100">
          <Box
            sx={{
              ml: "2rem",
            }}
          >
            <h4>{t("SEARCH RESULT")}</h4>
          </Box>
          <table className="table table-striped bg-light mt-3 p-3 border-white rounded-4 overflow-hidden">
            <thead>
              <tr className="text-white background-orange text-truncate">
                {headCells.map((headCell) => (
                  <th
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                    align={headCell.align}
                    style={{
                      background: "#F87B03",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {headCell.disableSorting ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {eventList?.length > 0 ? (
              <tbody>
                {eventList.map((event) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "center" }}>{event.firstName}</td>
                      <td style={{ textAlign: "center" }}>{event.lastName}</td>
                      <td style={{ textAlign: "center" }}>
                        {event.postcode
                          ? event.postcode
                          : event.addrPostCode
                          ? event.addrPostCode
                          : ""}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {phoneNoMapping(event.mobileNumber)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {event.membershipCard
                          ? event.membershipCard
                          : event.memberCardNumber
                          ? event.memberCardNumber
                          : ""}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Link
                            onClick={() => {
                              setShowModel(!showModel);
                              setFindMemberID(event.memberID);
                              resetField();
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            Update
                          </Link>
                          <Link
                            onClick={() => {
                              setSelectedUserId(event);
                              setIsCollapsed(true);
                              resetField();
                            }}
                            style={{
                              textDecoration: "none",
                              marginLeft: "15px",
                              color: "green",
                            }}
                          >
                            Select
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={10} align="center">
                    No Member Found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <AddExpense
          eventID={currentEventId}
          modal={addExpense}
          setModal={setAddExpense}
        ></AddExpense>
      </Box>
    </div>
  );
}
