import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import EditMember from "./components/pages/EditMember";
import ForgotPassword from "./components/ForgotPassword";
import MailSent from "./components/MailSent";
import ChangePassword from "./components/ChangePassword";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/home" element={<Sidebar page="home" />} />
        <Route path="/members" element={<Sidebar page="find-member" />} />
        <Route
          path="/member-details"
          element={<Sidebar page="member-details" />}
        />
        <Route
          path="/newChangePassword"
          element={<Sidebar page="ResponsiveChangePassword" />}
        />
        <Route path="/eventDetails" element={<Sidebar page="EventDetails" />} />
        <Route path="/eventListing" element={<Sidebar page="EventListing" />} />
        <Route path="/editProfile" element={<Sidebar page="editProfile" />} />
        <Route path="/edit-member" element={<EditMember />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/MailSent" element={<MailSent />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/searchMember" element={<Sidebar page="SearchMember" />} />
        <Route
          path="/membership-details"
          element={<Sidebar page="MembershipDetails" />}
        />
        <Route
          path="/recordAttendance"
          element={<Sidebar page="RecordAttendance" />}
        />
      </Routes>
    </div>
  );
}

export default App;
