import "../../App.css";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
// import "react-phone-input-2/lib/style.css"
import "react-phone-input-2/lib/material.css";
// import { useTranslation } from "react-i18next";
import configData from "../../config.json";

function FindMember(props) {
  //const t = useTranslation();
  const t = props.t;
  const [model, setModel] = useState(false);
  const [email] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [firstName] = useState(null);
  const [lastName] = useState(null);
  const [countryCode] = useState();
  const [mobileNumber] = useState("");
  const [dob] = useState(null);
  const [gender] = useState(0);
  const [postCode] = useState(null);
  const [address, setAddress] = useState("");
  const [, setTown] = useState("");
  const [cardNumberValidation, setCardNumberValidation] = useState("");
  const [, setLastNameValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [, setDobError] = useState("");
  const [memberCardNumberError, setMemberCardNumberError] = useState("");
  const [, setGenderError] = useState("");
  const [, setEmailError] = useState("");
  const [, setIsRequired] = useState();
  const [, setIsValidate] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 12);

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);

  useEffect(() => {
    setModel(props.model);
  }, [props.model]);

  const handleModal = () => {
    setCardNumber("");
    setLastNameValidation(false);
    setErrorMessage("");
    props.parentCallback(false);
    setModel(false);
  };

  const fetchPostDetails = () => {
    fetch(`${configData.MEMBER_URL}Members/postcodelookup/${postCode}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 404) {
          setAddress("");
          setTown("");
        }

        if (data.result) {
          const postCodeData = data.result[0];
          setAddress(postCodeData.addr1);
          setTown(postCodeData.addrTown);
        }
      });
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (postCode) {
        fetchPostDetails();
      }
    }, 2000);

    return () => clearTimeout(delay);
  });

  const formattedDate = (date) => {
    let newDate = new Date(date);
    let mnth = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let day = ("0" + newDate.getDate()).slice(-2);
    return [newDate.getFullYear(), mnth, day].join("-");
    // return newDate.toDateString().replace(/^\S+\s/,'');
  };

  const handleSubmit = async (e) => {
    const genderValue = gender === "Male" ? 1 : gender === "Female" ? 2 : 0;
    var countryCodeValue = countryCode ? countryCode.replace(/\D/g, "") : "";
    let dobValue = dob ? formattedDate(dob) : "";
    let maxDate = new Date(new Date().toDateString());
    maxDate.setFullYear(maxDate.getFullYear() - 12);

    setDobError("");
    setMemberCardNumberError("");
    setCardNumberValidation("");

    if (cardNumber.length > 9) {
      setCardNumberValidation("Invalid member card number");
      return;
    }
    const memberData = {
      MemberCardNumber: parseInt(cardNumber) ?? null,
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: email,
      MobileCountyCode: countryCodeValue,
      MobileNumber: mobileNumber,
      DateOfBirth: dobValue,
      Gender: genderValue,
      Postcode: postCode,
      PostAddr0: address,
      PostCodeCountry: "India",
      IssuerTerritory: 0,
    };

    e.preventDefault();
    setIsRequired(false);
    setIsValidate(false);
    setErrorMessage("");
    setGenderError("");
    setEmailError("");

    fetch(`${configData.MEMBER_URL}Members/discover`, {
      method: "POST",
      body: JSON.stringify(memberData),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 401) {
          navigate("/");
        }
        if (data.result) {
          let memberID = data.result.memberID;
          localStorage.setItem("memberID", memberID);
          setModel(false);
          props.parentCallback(false);
          setCardNumber("");
          navigate("/member-details", { state: { memberID: memberID } });
        }
        if (data.status === 422 || data.status === 400) {
          setMemberCardNumberError("Member Number not recognised");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <Modal show={model} onHide={handleModal}>
        <Modal.Body>
          <div className="member-form d-flex align-items-center">
            <div className="container container-lg">
              <h4 className="m-3">
                <BsArrowLeft onClick={handleModal} /> {t("Find Member")}
              </h4>
              <div className={"row"}>
                <div className="col-lg-10 col-xl-11 mx-auto">
                  <form>
                    <span className="text-danger mb-2">{errorMessage}</span>
                    <h4 style={{ marginTop: "20px" }}>
                      {t("Enter the Member card number")}
                    </h4>
                    <div className="form-floating mb-2">
                      <input
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                        type="number"
                        className="form-control"
                        onChange={(e) => setCardNumber(e.target.value)}
                        placeholder="012345"
                      />
                      <label htmlFor="floatingInput">
                        {t("Enter the Member card number")}
                      </label>
                      {memberCardNumberError && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">
                            {memberCardNumberError}
                          </span>
                        </div>
                      )}
                      {cardNumberValidation && (
                        <div className="mb-2">
                          <span className="text-danger mb-2">
                            {cardNumberValidation}
                          </span>
                        </div>
                      )}
                    </div>
                    <button
                      style={{
                        position: "sticky",
                        right: 0,
                        bottom: "0.5rem",
                      }}
                      type="button"
                      disabled={
                        cardNumber || firstName || lastName ? false : true
                      }
                      className="btn background-orange width-100 text-white form-control p-3"
                      onClick={handleSubmit}
                    >
                      {t("Find")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FindMember;
