import { Box, Drawer, IconButton, Typography, Button } from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import configData from "./../../config.json";
import moment from "moment";

const AddExpense = ({
  eventID,
  modal,
  setModal,
  mode,
  setExpenseUpdated,
  getExpense,
  attendances,
}) => {
  const [expenseType, setExpenseType] = useState();
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState();
  const [eventExpenseTypes, setEventExpenseTypes] = useState([]);
  const [addExpenseSuccessMessage, setAddExpenseSuccessMessage] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [crewMember, setCrewMember] = useState();
  const [, setCrewMembers] = useState([]);
  const [entryTypeID, setEntryTypeID] = useState();
  const token = localStorage.getItem("token");
  const loginMemberId = localStorage.getItem("memberId");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchExpenditureData = async () => {
    const res = await fetch(
      `${configData.VENUE_URL}Events/${eventID}/getexpenditure`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const fetchExpenseTypesData = async () => {
    const res = await fetch(`${configData.VENUE_URL}Events/expensetypes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    if (!amount || !expenseType) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [amount, expenseType]);

  useEffect(() => {
    setExpenseType("");
    if (modal) {
      setAmount("");
      setDescription("");
      setModal(modal);
      setCrewMembers([]);
    }
    if (mode === "online") {
      fetchExpenseTypesData().then((data) => {
        if (data.isError) {
          navigate("/");
        }

        if (data.statusCode === 200) {
          const expenseTypes = data.payload;
          setEventExpenseTypes(expenseTypes);
        }
      });
    } else {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        //Fetching Expenses data from indexedDB
        let transaction2 = db.transaction(`Expensetypes`, "readonly");
        let objectStore2 = transaction2.objectStore(`Expensetypes`);
        let request2 = objectStore2.getAll();
        request2.onsuccess = (event) => {
          if (event.target.result.length) {
            setEventExpenseTypes(event.target.result[0]?.expensetypes);
          }
        };
      };
    }
  }, [modal]);

  const handleExpenseModal = (value) => {
    setModal(value);
  };

  const handleAddExpenseSubmit = async () => {
    const expenseData = {
      expenseTypeId: expenseType,
      eventId: eventID,
      amount: amount,
      description: description,
      memberId: loginMemberId,
      crewMemberId: crewMember,
    };
    if (mode === "online") {
      fetch(`${configData.VENUE_URL}Events/addexpense`, {
        method: "POST",
        body: JSON.stringify(expenseData),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 401) {
            navigate("/");
          }
          if (data.statusCode === 200) {
            setAmount("");
            setExpenseType("");
            setDescription("");
            setCrewMembers([]);
            setCrewMember("");
            setAddExpenseSuccessMessage(data.payload);
            fetchExpenditureData().then((data) => {
              if (data.isError) {
                navigate("/");
              }
            });
            getExpense();
            setModal(false);
            setTimeout(() => {
              setAddExpenseSuccessMessage("");
            }, 5000);
          }
          if (data.statusCode === 500) {
          }
          if (data.status === 422) {
            data.validationErrors.map((error) => {
              switch (error.name) {
                case "LastName":
                case "MemberPhysicalCardNumber":
                default:
                  return "";
              }
            });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      let selectedExpense;
      if (expenseType) {
        selectedExpense = eventExpenseTypes.filter(
          (e) => e.expenseTypeID === expenseType
        );
      }
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(
          `AddExpenditure${eventID}`,
          "readwrite"
        );
        let objectStore = transaction.objectStore(`AddExpenditure${eventID}`);
        objectStore.put({
          expenseTypeId: expenseType,
          expenseTypeName: selectedExpense[0]?.expenseTypeName,
          eventId: eventID,
          amount: parseInt(amount),
          description: description,
          memberId: loginMemberId,
          crewMemberId: crewMember,
          addedDate: moment().format("HH:mm"),
        });
        setAmount("");
        setExpenseType("");
        setDescription("");
        setCrewMembers([]);
        setCrewMember("");
        setAddExpenseSuccessMessage("Expense added successfully!");
        setModal(false);
        setExpenseUpdated(true);
        setTimeout(() => {
          setAddExpenseSuccessMessage("");
        }, 5000);
      };
    }
  };

  const fetchEventCrewMembersData = async (expenseTypeID) => {
    const res = await fetch(
      `${configData.VENUE_URL}Events/geteventcrewmember?EventID=${eventID}&EntryTypeID=${expenseTypeID}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  const handleExpenseType = (data) => {
    setCrewMembers([]);
    let explodeData = data.split("/");
    let expenseTypeId = explodeData[0];
    let entryTypeId = explodeData[1];
    setExpenseType(expenseTypeId);
    setEntryTypeID(entryTypeId);
    if (mode === "online") {
      if (
        entryTypeId &&
        entryTypeId !== "00000000-0000-0000-0000-000000000000"
      ) {
        fetchEventCrewMembersData(entryTypeId).then((data) => {
          if (data.isError) {
            navigate("/");
          }

          if (data.statusCode === 200) {
            const crewMembersData = data.payload;
            setCrewMembers(crewMembersData);
          }
        });
      }
    }
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: "30%",
          },
        }}
        anchor="right"
        open={modal}
        onClose={() => handleExpenseModal(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            m: "2rem",
          }}
        >
          <Typography>
            <IconButton onClick={() => setModal(false)}>
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>
            {t("Add Expense")}
          </Typography>
          <h5 className="text-success">
            {addExpenseSuccessMessage ? addExpenseSuccessMessage + "!" : ""}
          </h5>
          <div className="form-floating mb-2">
            <select
              className="form-select mt-2"
              aria-label="Floating label select example"
              value={`${expenseType}/${entryTypeID}`}
              onChange={(e) => handleExpenseType(e.target.value)}
            >
              <option defaultValue="0">{t("Select Expense Type")}</option>
              {eventExpenseTypes &&
                eventExpenseTypes.map((eventExpenseType, index) => (
                  <option
                    key={index}
                    value={`${eventExpenseType.expenseTypeID}/${eventExpenseType.entryTypeID}`}
                  >
                    {eventExpenseType.expenseTypeName}
                  </option>
                ))}
            </select>
            <label htmlFor="floatingSelect">{t("Expense Type")}</label>
          </div>

          {attendances && attendances?.length > 0 && (
            <div className="form-floating mb-2">
              <select
                className="form-select mt-2"
                aria-label="Floating label select example"
                value={crewMember}
                onChange={(e) => setCrewMember(e.target.value)}
              >
                <option defaultValue="0">{t("Select Crew Member")}</option>
                {attendances &&
                  attendances.map((crewMember) => (
                    <option value={crewMember.memberID}>
                      {`${crewMember.firstName} ${crewMember.lastName}`}
                    </option>
                  ))}
              </select>
              <label htmlFor="floatingSelect">{t("Crew Member")}</label>
            </div>
          )}

          <div className="form-floating mb-2">
            <input
              type="number"
              className="form-control"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              placeholder="Amount"
            />
            <label htmlFor="floatingInput">{t("Amount")}</label>
          </div>
          <div className="form-floating mb-2">
            <textarea
              type="text"
              rows="3"
              className="form-control"
              onChange={(e) =>
                setDescription(e.target.value.replace(/[^\w\s]/gi, ""))
              }
              maxLength={560}
              value={description}
              placeholder="Description"
            >
              {description}
            </textarea>
            <label htmlFor="floatingInput">{t("Description")}</label>
          </div>
        </Box>
        <Button
          sx={{
            textTransform: "none",
            width: "90%",
            backgroundColor: "orange",
            m: "auto",
            color: "#fff",
            p: "0.5rem 0.5rem",
            "&:hover": {
              backgroundColor: "orange",
            },
          }}
          onClick={handleAddExpenseSubmit}
          disabled={submitDisabled}
        >
          {t("Submit")}
        </Button>
      </Drawer>
    </>
  );
};

export default AddExpense;
