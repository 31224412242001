import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PasswordValidation = ({ newPassword, setNewPasswordStatus }) => {
  useEffect(() => {
    handleChange();
  }, [newPassword]);
  const [state, setState] = useState({
    passwordLength: false,
    containsNumber: false,
    isUpperCase: false,
    containsSymbol: false,
  });

  //check for Number
  const checkForNumber = (targetValue) => {
    let matches = targetValue.match(/\d+/g);
    return !!matches;
  };

  //check for special character
  const checkForSymbol = (targetValue) => {
    let symbols = new RegExp(/[^A-Za-z0-9]/);
    return !!symbols.test(targetValue);
  };
  //UPPERCASE
  const checkForCase = (targetValue) => {
    let matches = targetValue.match(/[A-Z]/);
    return !!matches;
  };

  //PasswordLength Checker
  const handleChange = () => {
    setState(() => {
      let status = true;
      const newState = {
        passwordLength: newPassword.length > 7 ? true : false,
        isUpperCase: checkForCase(newPassword),
        containsNumber: checkForNumber(newPassword),
        containsSymbol: checkForSymbol(newPassword),
      };
      for (let i in newState) {
        if (newState[i] === false) {
          status = false;
          break;
        }
      }
      setNewPasswordStatus(status);
      return newState;
    });
  };

  return (
    <div>
      <FormControl>
        <FormLabel>Password Checklist</FormLabel>
        <FormGroup sx={{ fontSize: "small", color: "grey", p: 1 }}>
          <FormControlLabel
            label="Minimum 8 and Max 24 character"
            control={
              state.passwordLength ? (
                <CheckCircleIcon fontSize="small" color="primary" />
              ) : (
                <CheckCircleOutlineIcon fontSize="small" />
              )
            }
          />
          <FormControlLabel
            label="Atleast one UPPERERCASE and lowercase"
            control={
              state.isUpperCase ? (
                <CheckCircleIcon fontSize="small" color="primary" />
              ) : (
                <CheckCircleOutlineIcon fontSize="small" />
              )
            }
          />
          <FormControlLabel
            label="Atleast one number(0-9)"
            control={
              state.containsNumber ? (
                <CheckCircleIcon fontSize="small" color="primary" />
              ) : (
                <CheckCircleOutlineIcon fontSize="small" />
              )
            }
          />
          <FormControlLabel
            label="Atleast one special character"
            control={
              state.containsSymbol ? (
                <CheckCircleIcon fontSize="small" color="primary" />
              ) : (
                <CheckCircleOutlineIcon fontSize="small" />
              )
            }
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default PasswordValidation;
