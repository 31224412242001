export function phoneNoMapping(value) {
    if(value){
      return value.replace(/.(?=.{4})/g, 'x');
    }else{
      return ""
    }
  }

  export const maskEmail = (email = '') => {
    if(email && email.length > 1 && email.includes('@')){
      const [name, domain] = email.split('@');
    const { length: nameLen } = name;
    const { length: domainLen } = domain;
    const maskedName = name[0] + '...' + name[nameLen - 1];
    const maskedDomain = domain[0] + '...' + domain[domainLen - 1];
    const maskedEmail = maskedName + '@' + maskedDomain;
    return maskedEmail;
    }else{
      return email
    }
 };

 export const validateEmailReg = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
}