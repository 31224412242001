import * as React from "react";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link } from "react-router-dom";
import "./../../App.css";
import moment from "moment";
// import { useTranslation } from "react-i18next";

export default function RecordAttendanceList({
  eventList = [],
  t,
  mode,
  toggle,
  setDeletedUserId,
  setShowModel,
  setFindMemberID,
  showModel,
  resetField,
  setUpdateAttendance,
  setAttendance,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) {
  const userStyle = {};

  const visitorStyle = {
    background: "#ea9b9b",
  };

  const voucherStyle = {
    background: "#f0a3f0",
  };

  const headCells = [
    {
      id: mode === "online" ? "memberCard" : "memberCardNumber",
      label: `${t("Member No.")}`,
      disableSorting: false,
      align: "right",
    },
    {
      id: mode === "online" ? "fullName" : "firstName",
      label: `${t("Name")}`,
      disableSorting: false,
      align: "right",
    },
    {
      id: mode === "online" ? "entryType" : "entryTypeName",
      label: `${t("Entry Type")}`,
      disableSorting: false,
      align: "right",
    },
    {
      id: "entryFeePaid",
      label: `${t("Paid £")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: mode === "online" ? "paymentType" : "paymentMethodName",
      label: `${t("Payment")}`,
      disableSorting: false,
      align: "right",
    },
    {
      id: mode === "online" ? "entryDateTime" : "entryTime",
      label: `${t("Entered")}`,
      disableSorting: false,
      align: "right",
    },
    {
      id: "Entry",
      label: `${t("Entry")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "member",
      label: `${t("Member")}`,
      disableSorting: true,
      align: "right",
    },
  ];
  React.useEffect(() => {
    if (eventList && mode !== "online") {
      setOrderBy("entryTime");
      setOrder("desc");
    }
  }, [eventList]);

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  return (
    <div
      style={
        toggle
          ? { overflowX: "auto", maxHeight: "500px" }
          : { overflowX: "auto", maxHeight: "500px" }
      }
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <div className="w-100">
          <table className="table table-striped bg-light mt-3 p-3 border-white rounded-4 overflow-hidden">
            <thead>
              <tr className="text-white background-orange text-truncate">
                {headCells.map((headCell) => (
                  <th
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                    align={headCell.align}
                    style={{
                      background: "#F87B03",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {headCell.disableSorting ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {eventList?.length > 0 ? (
              <tbody>
                {eventList.map((event) => {
                  let isVisitor =
                    event?.firstName === "New" || event?.name === "New"
                      ? true
                      : false;
                  let VoucherCheck =
                    event?.entryTypeName?.includes("Voucher") &&
                    (!event?.voucherNumber || event?.voucherNumber?.length < 4)
                      ? true
                      : false;
                  return (
                    <tr>
                      <td
                        style={
                          isVisitor
                            ? visitorStyle
                            : userStyle && VoucherCheck
                            ? voucherStyle
                            : null
                        }
                      >
                        {event.memberCardNumber
                          ? event.memberCardNumber
                          : event.MemberCardNumber}
                      </td>
                      <td style={userStyle}>
                        {event.fullName
                          ? event.fullName
                          : event?.firstName
                          ? `${event?.firstName} ${event?.lastName}`
                          : event?.name}
                      </td>
                      <td style={userStyle}>
                        {event.entryTypeName
                          ? event?.entryTypeName
                          : event?.EventTypeName}
                      </td>
                      <td style={userStyle}>
                        {event?.entryFeePaid
                          ? `${
                              event?.entryFeePaid ===
                              Math.floor(event?.entryFeePaid)
                                ? `${event?.entryFeePaid.toLocaleString()}.00`
                                : `${event?.entryFeePaid.toLocaleString()}`
                            }`
                          : "0.00"}
                      </td>
                      <td style={userStyle}>
                        {event?.paymentType
                          ? event.paymentType
                          : event?.paymentMethodName
                          ? event?.paymentMethodName
                          : event.PaymentMethodName}
                      </td>
                      <td style={userStyle}>
                        {event.entryDateTime
                          ? moment(event.entryDateTime).format("HH:mm")
                          : event.entryTime
                          ? moment(event.entryTime).format("HH:mm")
                          : "N/A"}
                      </td>
                      <td style={userStyle}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Link
                            onClick={() => {
                              setAttendance(event);
                              resetField();
                              setUpdateAttendance(true);
                            }}
                            style={{ textDecoration: "none", color: "green" }}
                          >
                            Edit
                          </Link>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletedUserId(event);
                            }}
                            style={{
                              textDecoration: "none",
                              marginLeft: "10px",
                              marginRight: "10px",
                              color: "red",
                            }}
                          >
                            Delete
                          </Link>
                        </div>
                      </td>
                      <td style={userStyle}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Link
                            onClick={() => {
                              if (mode !== "online") {
                                if (
                                  event?.firstName === "New" ||
                                  event?.firstName === "new"
                                ) {
                                  setFindMemberID({ isNew: true, ...event });
                                } else {
                                  setFindMemberID(event.memberID);
                                }
                              } else {
                                setFindMemberID(event.memberID);
                              }
                              setShowModel(!showModel);
                              resetField();
                            }}
                            style={{ textDecoration: "none", color: "green" }}
                          >
                            Update
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={10} align="center">
                    No member added yet
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </Box>
    </div>
  );
}
