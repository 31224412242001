import { IconButton, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next"
import { BsArrowLeft } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import {
  phoneNoMapping,
  maskEmail,
  validateEmailReg,
} from "../CommonFunctions";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import SuccessImage from "../../images/success.png";

const MemberDetailModalOffline = ({
  activeUsers,
  showModel,
  findMemberID,
  setShowModel,
  setFindMemberID,
  setUpdatedMember,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [postCode, setPostCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [dob, setDob] = useState(new Date());
  const [dobFlag, setDobFlag] = useState("");
  const [flatFlag, setFlatFlag] = useState("");
  const [gender, setGender] = useState("");
  // const [flatNumber, setFlatNumber] = useState("");
  const [town, setTown] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [, setTownError] = useState("");
  const [dobError, setDobError] = useState("");
  // const [editModel,setEditModel] = useState(false)
  const [memberId, setMemberId] = useState("");
  const [emailFlag, setEmailFlag] = useState("");
  const [phoneFlag, setPhoneFlag] = useState("");
  const [flatNumber, setFlatNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [area, setArea] = useState("");
  const [county, setCounty] = useState("");
  const [addrCountryCode, setAddrCountryCode] = useState("");
  const [updateMemberSuccess, setUpdateMemberSuccess] = useState(false);
  const [, setPostAreaList] = useState([]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isUnknown, setIsUnknown] = useState();
  const [isValid, setIsValid] = useState(false);

  const fetchMemberData = async (findMember) => {
    if (findMember?.isNew) {
      return findMember;
    } else {
      return activeUsers.find((e) => e.memberID === findMember) ?? {};
    }
  };

  useEffect(() => {
    if (findMemberID) {
      fetchMemberData(findMemberID).then((data) => {
        if (!data) {
          navigate("/home");
        }
        let date = moment(data?.dateOfBirth).format("DD/MM/YYYY");
        setMemberId(data?.memberID);
        setFirstName(data?.firstName);
        setMemberNumber(data?.memberCardNumber);
        setLastName(data?.lastName);
        if (
          (data?.firstName === "New" || data?.firstName === "Visitor") &&
          !data?.lastName
        ) {
          setIsAnonymous(true);
        }
        setEmail(data?.emailId);
        setEmailFlag(data?.emailId ? maskEmail(data?.emailId) : "");
        setMobileNumber(
          data?.mobileNumber && data?.mobileNumber.length > 10
            ? data?.mobileNumber.substring(data?.mobileNumber.length - 10)
            : data?.mobileNumber
        );
        setPhoneFlag(
          data?.mobileNumber && data?.mobileNumber.length > 10
            ? phoneNoMapping(
                data?.mobileNumber.substring(data?.mobileNumber.length - 10)
              )
            : data?.mobileNumber
            ? phoneNoMapping(data?.mobileNumber)
            : ""
        );
        setMobileCountryCode(
          data?.MobileCountyCode ? data?.MobileCountyCode.toString() : "44"
        );
        setDob(date);
        setDobFlag(date ? "**/**/****" : "");
        setGender(data?.gender);
        setFlatNumber(data?.addr0);
        setFlatFlag(data?.addr0 ? "********" : "");
        setStreetNumber(data?.postAddr1);
        if (data?.addrPostcode) {
          setPostAreaList([data?.addr1]);
        }
        setArea(data?.addr2);
        setAddrCountryCode(data?.addrCountryCode);
        setCounty(data?.addrCounty);
        setPostCode(data?.addrPostCode);
        setTown(data?.addrTown);
        setShowModel(true);
        setMobileNumberError("");
        setAddressError("");
        setTownError("");
        setDobError("");
      });
    }
  }, [findMemberID]);

  useEffect(() => {
    if (
      firstName?.length <= 2 ||
      lastName?.length <= 2 ||
      !validateEmailReg(email) ||
      !gender
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [firstName, lastName, email, gender]);

  useEffect(() => {
    if (isUnknown && memberNumber.length > 1) {
      setEmailFlag("");
      setEmail(`${memberNumber}@ceroc-member-without-email.co.uk`);
    }
    if (isUnknown === false) {
      setEmailFlag("");
      setEmail("");
    }
  }, [isUnknown]);

  const handleModal = (data) => {
    if (!showModel) {
      let date = moment(data?.dateOfBirth).format("DD/MM/YYYY");
      setMemberId(data?.memberID);
      setDob(date);
      setDobFlag(date ? "**/**/****" : "");
    } else {
      setShowModel(false);
      setFindMemberID();
      setMemberId();
      setFirstName("");
      setMemberNumber("");
      setLastName("");
      setIsUnknown(false);
      setEmail("");
      setEmailFlag("");
      setMobileNumber("");
      setPhoneFlag("");
      setMobileCountryCode("44");
      setDob();
      setDobFlag("");
      setGender();
      setFlatNumber();
      setStreetNumber();
      setArea();
      setAddrCountryCode();
      setCounty();
      setPostCode();
      setTown();
      setMobileNumberError("");
      setAddressError("");
      setTownError("");
      setDobError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mobileNumber || mobileNumber?.length !== 10) {
      setMobileNumberError("Entered Mobile is Not Valid");
      return;
    }
    if (!dob) {
      setDobError("Please enter Date of Birth");
      return;
    }
    if (!email) {
      setEmailError("Please enter email");
      return;
    }
    if (!postCode) {
      setAddressError("Post code field is missing");
      return;
    }

    setEmailError("");
    setMobileNumberError("");
    setAddressError("");
    setTownError("");
    setDobError("");

    const putData = {
      firstName: firstName,
      lastName: lastName,
      MemberID: findMemberID?.isNew ? findMemberID?.memberID : findMemberID,
      email: email,
      gender: parseInt(gender),
      MobileNumber: mobileNumber ?? "",
      MobileCountryCode: mobileCountryCode ?? 44,
      PostAddr0: flatNumber ?? "",
      postalcode: postCode ?? "",
      PostAddr1: streetNumber ?? "",
      PostAddr2: area ?? "",
      AddrCounty: county ?? "",
      PostAddrTown: town ?? "",
      PostcodeCountry: addrCountryCode ?? "",
      updatedBy: memberId,
      dateOfBirth: dob
        ? moment(dob).format("YYYY-MM-DD")
          ? moment(dob).format("YYYY-MM-DD")
          : ""
        : "",
    };
    if (isAnonymous) {
      putData.isAnonymous = true;
      putData.email = email;
    }
    let request = window.indexedDB.open("MyDatabase", 1);
    request.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction("UpdateMember", "readwrite");
      let objectStore = transaction.objectStore("UpdateMember");
      let requestsArray = [];
      let requestObject;
      // get the existing object from the object store if it exists
      let requestObjectRequest = objectStore.get(1);
      requestObjectRequest.onsuccess = () => {
        requestObject = requestObjectRequest.result;
        setUpdatedMember(putData);
        // if the object doesn't exist, create it
        if (!requestObject) {
          requestObject = { updateMemberRequests: [], id: 1 };
        } else {
          // otherwise, get the existing requests array
          requestsArray = requestObject.updateMemberRequests;
        }

        // add each request to the array
        requestsArray.push(putData);

        //update the object in object store
        requestObject.updateMemberRequests = requestsArray;
        objectStore.put(requestObject);
        setUpdateMemberSuccess(true);
      };
    };
  };

  const handleBlurredInput = (e, type) => {
    switch (type) {
      case "email":
        if (emailFlag) {
          setEmail("");
          setEmailFlag("");
        } else {
          setEmail(e.target.value);
        }
        break;
      case "phone":
        if (phoneFlag) {
          setPhoneFlag("");
          setMobileNumber("");
        } else {
          setMobileNumber(e.target.value);
        }
        break;
      case "dob":
        if (dobFlag) {
          setDobFlag("");
          setDob("");
        } else {
          setDob(e.target.value);
        }
        break;
      case "flat":
        if (flatFlag) {
          setFlatFlag("");
          setFlatNumber("");
        } else {
          setFlatNumber(e.target.value);
        }
        break;
      default:
        break;
    }
  };

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value);
  };

  const handleDate = (e) => {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;

    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (len === 2) {
      input.value += "/";
    }

    if (len === 5) {
      input.value += "/";
    }
  };

  useEffect(() => {
    if (updateMemberSuccess) {
      setTimeout(() => {
        setUpdateMemberSuccess(false);
        setShowModel(false);
        setFindMemberID();
      }, 1000);
    }
  }, [updateMemberSuccess]);

  return (
    <div>
      <Modal className="edit-modal" show={showModel} onHide={handleModal}>
        <Modal.Body>
          <div className="member-form d-flex align-items-center">
            <div className="container">
              <div className="row">
                {!updateMemberSuccess ? (
                  <div className="col-lg-10 col-xl-11 mx-auto">
                    <form onSubmit={handleSubmit}>
                      <h4 className="mb-3">
                        <BsArrowLeft
                          onClick={handleModal}
                          style={{ marginRight: "10px" }}
                        />
                        {"Update Member"}
                      </h4>

                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="border form-control shadow-none bg-light"
                          value={memberNumber ?? ""}
                          readOnly
                          placeholder="Member Card Number"
                        />
                        <label htmlFor="floatingInput">
                          {"Member Card Number"}
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="border form-control shadow-none"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName ?? ""}
                          placeholder="First Name"
                        />
                        <label htmlFor="floatingInput">{"First Name"}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="border form-control shadow-none"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName ?? ""}
                          placeholder="Last Name"
                        />
                        <label htmlFor="floatingInput">{"Last Name"}</label>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          className="form-floating mb-2"
                          style={{ flex: "3" }}
                        >
                          <input
                            // type="email"
                            className="form-control border shadow-none"
                            onChange={(e) => handleBlurredInput(e, "email")}
                            value={emailFlag ? emailFlag : email ? email : ""}
                            placeholder="Email"
                          />
                          <label htmlFor="floatingInput">{"Email"}</label>
                          {emailError && (
                            <div className="mb-2">
                              <span className="text-danger mb-2">
                                {emailError}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            marginLeft: "4px",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>Unknown</span>
                          <input
                            value={isUnknown}
                            disabled={memberNumber?.length < 3}
                            onClick={(e) => {
                              setIsUnknown(e.target.checked);
                            }}
                            style={{ height: "20px", width: "20px" }}
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-floating col-md-4 mb-2 pr-1">
                          <PhoneInput
                            style={{ width: "2rem" }}
                            country={"uk"}
                            className="mt-2"
                            specialLabel={"Country Code"}
                            value={mobileCountryCode ?? ""}
                            onChange={(abc) => setMobileCountryCode(abc)}
                          />
                        </div>
                        <div className="form-floating col-md-8 mb-2">
                          <input
                            type="text"
                            onChange={(e) => handleBlurredInput(e, "phone")}
                            value={
                              phoneFlag
                                ? phoneFlag
                                : mobileNumber
                                ? mobileNumber
                                : ""
                            }
                            maxLength={10}
                            placeholder="Mobile Number"
                            className="form-control mb-2 mt-2"
                          />
                          <label htmlFor="floatingInput">
                            {"Mobile Number"}
                          </label>
                          {mobileNumberError && (
                            <div className="mb-2">
                              <span className="text-danger mb-2">
                                {mobileNumberError}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          disabled={true}
                          maxLength="10"
                          placeholder="dd/mm/yyyy"
                          onKeyPress={handleDate}
                          sx={{ width: "100%" }}
                          value={dobFlag ? dobFlag : dob ? dob : ""}
                          onChange={(e) => handleBlurredInput(e, "dob")}
                        />
                        <label htmlFor="floatingInput">
                          {t("Date of Birth (dd/mm/yyyy)")}
                        </label>
                        {dobError && (
                          <div className="mb-2">
                            <span className="text-danger">{dobError}</span>
                          </div>
                        )}
                      </div>

                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          value={gender ?? ""}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="0">Unknown</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                        <label htmlFor="floatingSelect">{"Gender"}</label>
                      </div>
                      <div className="form-floating mb-2">
                        <select
                          className="form-select mt-2"
                          aria-label="Floating label select example"
                          value={addrCountryCode ?? "UK"}
                          onChange={(e) => setAddrCountryCode(e.target.value)}
                        >
                          <option value="UK">UK- United Kingdom</option>
                          <option value="IE">IE- Ireland</option>
                          <option value="FR">FR - France</option>
                          <option value="NL">NL - Netherlands </option>
                        </select>
                        <label htmlFor="floatingSelect">
                          {"Address Country Code"}
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => handlePostCodeChange(e)}
                          value={postCode ?? ""}
                          placeholder="Postcode"
                        />
                        <label htmlFor="floatingInput">{"Postcode"}</label>
                        {addressError && (
                          <div className="mb-2">
                            <span className="text-danger">{addressError}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTown(e.target.value)}
                          value={town ?? ""}
                          placeholder="Address"
                        />
                        <label htmlFor="floatingInput">{t("Town")}</label>
                      </div>
                      <button
                        style={{
                          position: "sticky",
                          right: 0,
                          bottom: "0.5rem",
                          zIndex: "100",
                        }}
                        disabled={!isValid}
                        type="submit"
                        className={`btn ${
                          isValid ? "background-green" : "background-red"
                        } form-control mt-3 text-white`}
                      >
                        {"Update Now"}
                      </button>
                    </form>
                  </div>
                ) : (
                  <Box>
                    <Typography>
                      <IconButton>
                        <KeyboardBackspaceOutlinedIcon />
                      </IconButton>
                      Success
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "7rem",
                        gap: "2rem",
                      }}
                    >
                      <img width="50%" src={SuccessImage} alt="" />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                          backgroundColor: "lightgrey",
                          padding: "0.7rem",
                        }}
                      >
                        <Typography variant="h6" sx={{ color: "green" }}>
                          Successful!
                        </Typography>
                        <Typography variant="paragraph">
                          {"Member offline updated successfully!"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MemberDetailModalOffline;
