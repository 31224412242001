import {
  Alert,
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import SuccessImage from "../../images/success.png";
import "react-datepicker/dist/react-datepicker.css";
import configData from "./../../config.json";

const CreateEventDrawer = ({ openDrawer, setOpenDrawer, t }) => {
  //const t = useTranslation();
  const [createEventSuccess, setCreateEventSuccess] = useState(false);
  const memberId = localStorage.getItem("memberId");
  const token = localStorage.getItem("token");
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [venue, setVenue] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [venueRoom, setVenueRoom] = useState([]);
  const [selectedVenueRoom, setSelectedVenueRoom] = useState("");
  const [eventType, setEventType] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [venueId] = useState("");
  const [duration, setDuration] = useState(3);

  // Checking the values if anyone is empty
  const [validateEventName, setValidateEventName] = useState(false);
  const [validateStartDate, setValidateStartDate] = useState(false);
  const [validateStartTime, setValidateStartTime] = useState(false);
  const [validateDuration, setValidateDuration] = useState(false);
  const [validateVenue, setValidateVenue] = useState(false);
  const [validateVenueRoom, setValidateVenueRoom] = useState(false);
  const [validateEventType, setValidateEventType] = useState(false);
  // Checking the status of the Event
  const [eventStatusMessage, setEventStatusMessage] = useState("");
  const [createEventSuccessMessage, setCreateEventSuccessMessage] = useState();
  const [minDate, setMinDate] = useState("");
  const territoryNumber = localStorage.getItem("territoryNumber");
  //const minDate = new Date();

  useEffect(() => {
    let date = new Date();
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    setMinDate(currentDate);
    setStartDate(currentDate);

    axios
      .get(`${configData.VENUE_URL}Venue/getvenuetypes/${territoryNumber}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setVenue(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${configData.VENUE_URL}Events/geteventtypes/${territoryNumber}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEventType(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedVenue) {
      axios
        .get(
          `${configData.VENUE_URL}Venue/${selectedVenue?.venueID}/getvenueroomtypes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setVenueRoom(res.data.payload);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedVenue, venueId]);

  const handleCreateEvent = () => {
    if (!eventName) {
      setValidateEventName(true);
    }
    if (!startDate) {
      setValidateStartDate(true);
    }
    if (!startTime) {
      setValidateStartTime(true);
    }
    if (!duration) {
      setValidateDuration(true);
    }
    if (!selectedVenue) {
      setValidateVenue(true);
    }
    if (!selectedVenueRoom) {
      setValidateVenueRoom(true);
    }
    if (!selectedEventType) {
      setValidateEventType(true);
    }
    if (
      !eventName ||
      !startDate ||
      !startTime ||
      !duration ||
      !selectedVenue ||
      !selectedVenueRoom ||
      !selectedEventType
    )
      return;

    const createEventDetails = {
      venueID: selectedVenue?.venueID,
      venueRoomID: selectedVenueRoom?.venueRoomID,
      memberId: memberId,
      eventStartDate: startDate,
      eventStartTime: startTime,
      eventDuration: duration * 1,
      eventTypeName: selectedEventType,
      eventName: eventName,
      territoryNumber: parseInt(territoryNumber),
    };

    axios
      .post(
        `${configData.VENUE_URL}Events/createevent`,
        JSON.stringify(createEventDetails),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (!res.data.isSuccess) {
          setEventStatusMessage(res.data.errorMessage);
        } else {
          setCreateEventSuccessMessage(res.data.payload);
          setCreateEventSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "30%",
            height: "100vh",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {!createEventSuccess ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                m: "2rem",
              }}
            >
              <Typography>
                <IconButton onClick={() => setOpenDrawer(false)}>
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
                {t("Create Event")}
              </Typography>
              {eventStatusMessage ? (
                <Alert severity="error">{eventStatusMessage}</Alert>
              ) : null}
              <TextField
                sx={{ width: "100%" }}
                size="small"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                label={t("Event Name")}
              />
              {validateEventName ? (
                <Alert severity="error">{t("Event Name is Required")}</Alert>
              ) : null}
              <TextField
                sx={{ width: "100%" }}
                size="small"
                //label="Date"
                type="date"
                inputProps={{
                  min: minDate,
                  max: minDate,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                onClick={(e) => e.target.showPicker()}
                label={t("Date")}
                InputLabelProps={{ shrink: true }}
              />
              {validateStartDate ? (
                <Alert severity="error">{t("Start Date is Required")}</Alert>
              ) : null}
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                onClick={(e) => e.target.showPicker()}
                label={t("Time")}
                InputLabelProps={{ shrink: true }}
              />
              {validateStartTime ? (
                <Alert severity="error">{t("Start Time is Required")}</Alert>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Duration")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  label={t("Duration")}
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                </Select>
              </FormControl>
              {validateDuration ? (
                <Alert severity="error">{t("Duration is Required")}</Alert>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Venue")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 4.5 + 4,
                      },
                    },
                  }}
                  //   labelId="demo-simple-select-label-1"
                  //name="selectVenue"
                  label={t("Venue")}
                  value={selectedVenue}
                  onChange={(e) => setSelectedVenue(e.target.value)}
                >
                  {venue.map((venue) => (
                    <MenuItem value={venue}>{venue.venueName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {validateVenue ? (
                <Alert severity="error">{t("Venue is Required")}</Alert>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Venue Room")}</InputLabel>
                <Select
                  //labelId="demo-simple-select-label"
                  //name="selectVenueRoom"
                  label={t("Venue Room")}
                  value={selectedVenueRoom}
                  onChange={(e) => setSelectedVenueRoom(e.target.value)}
                >
                  {venueRoom?.map((venueRoomItem) => (
                    <MenuItem value={venueRoomItem}>
                      {venueRoomItem.roomName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {validateVenueRoom ? (
                <Alert severity="error">{t("Venue Room is Required")}</Alert>
              ) : null}
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel>{t("Event Type")}</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 30 * 4 + 3,
                      },
                    },
                  }}
                  label={t("Event Type")}
                  value={selectedEventType}
                  onChange={(e) => setSelectedEventType(e.target.value)}
                >
                  {eventType.map((eventType) => (
                    <MenuItem value={eventType}>{eventType}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {validateEventType ? (
                <Alert severity="error">{t("Event Type is Required")}</Alert>
              ) : null}
            </Box>
            <Button
              sx={{
                textTransform: "none",
                width: "90%",
                backgroundColor: "#F87B03",
                m: "auto",
                color: "#fff",
                p: "0.5rem 0.5rem",
                "&:hover": {
                  backgroundColor: "#F87B03",
                },
              }}
              onClick={handleCreateEvent}
            >
              {t("Create")}
            </Button>
          </>
        ) : (
          <>
            <Typography>
              <IconButton onClick={() => setOpenDrawer(false)}>
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>
              Success
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: "7rem",
                gap: "2rem",
              }}
            >
              <img width="50%" src={SuccessImage} alt="" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  backgroundColor: "lightgrey",
                  padding: "0.7rem",
                }}
              >
                <Typography variant="h6" sx={{ color: "green" }}>
                  Successful!
                </Typography>
                <Typography variant="paragraph">
                  {createEventSuccessMessage}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default CreateEventDrawer;
