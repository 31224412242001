import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MailSent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        borderTop: 10,
        borderTopColor: "#F87B03",
        height: "100vh",
        width: "100%",
      }}
    >
      <img
        className="mil-se-div"
        src="./Images/Logo.png"
        alt="logo"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <img
          style={{ width: "60%" }}
          src="./Images/MailSent.png"
          alt="forgotPassword Image"
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "lightGrey",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
          gap: 1.2,
          p: 5,
          flex: 1
        }}
      >
        <h1>{t("MAIL SENT")}</h1>
        <p>{t("Please Check your Email")}</p>
        <Button
          sx={{
            mt: 1,
            p: 1,
            width: "100%",
            backgroundColor: "#F87B03",
            "&:hover": {
              backgroundColor: "#F87B03",
            },
          }}
          variant="contained"
          onClick={handleChange}
        >
          {t("Sign In")}
        </Button>
      </Box>
    </Box>
  );
};

export default MailSent;
