import axios from "axios";
import React from "react";
import { useEffect } from "react";
import configData from "./../../config.json";

const SyncRecordAttendanceData = ({
  eventID,
  handleCallback,
  createAttendanceCallback,
  updateAttendanceCallback,
  deleteAttendanceData,
  offlineDataCallback,
  addMemberSyncDataCallback,
  offlineCreateAttendanceData,
  offlineUpdateMemberData,
  offlineRecordAttendanceData,
  offlineUpdateAttendanceData,
  offlineUpdateAddExpenditureData,
  offlineDeleteExpenseData,
  setSync,
  // addMemberOfflineData
}) => {
  const token = localStorage.getItem("token");
  let singleCallFLag = 0;

  // Syncing Record Attendance Data
  const syncOfflineMemberRecordAttendance = async (memberList) => {
    let newMemberList = [];
    memberList.filter((memberAttendance) => {
      if (typeof memberAttendance.id === "number") {
        newMemberList.push(memberAttendance);
      }
    });

    if (newMemberList?.length > 0) {
      axios
        .put(
          `${configData.VENUE_URL}Attendance/recordattendance`,
          JSON.stringify(memberList),
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          createAttendanceCallback(res.data);
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `RecordAttendance${eventID}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(
              `RecordAttendance${eventID}`
            );
            objectStore.clear();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Syncing Update Attendance Data
  const syncOfflineMemberUpdateAttendance = async (memberList) => {
    let newMemberList = [];
    memberList.filter((memberAttendance) => {
      if (typeof memberAttendance.id === "number") {
        newMemberList.push(memberAttendance);
      }
    });
    if (newMemberList?.length > 0) {
      await axios
        .put(
          `${configData.VENUE_URL}Attendance/updateattendance`,
          JSON.stringify(memberList),
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          updateAttendanceCallback(res.data);
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `UpdateAttendance${eventID}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(
              `UpdateAttendance${eventID}`
            );
            objectStore.clear();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Syncing Create Attendance Data
  const syncOfflineMemberCreateAttendance = async (memberList) => {
    let request = window.indexedDB.open("MyDatabase", 1);

    let newMemberList = [];
    memberList.filter((memberAttendance) => {
      if (typeof memberAttendance.id === "number") {
        newMemberList.push(memberAttendance);
      }
    });
    request.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction("AddMember", "readonly");
      let objectStore = transaction.objectStore("AddMember");
      let request = objectStore.getAll();
      request.onsuccess = (event) => {
        if (event.target.result) {
          if (newMemberList?.length > 0) {
            axios
              .post(
                `${configData.VENUE_URL}Attendance/createattendance`,
                JSON.stringify(memberList),
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                createAttendanceCallback(res.data);
                let request = window.indexedDB.open("MyDatabase", 1);
                request.onsuccess = (event) => {
                  let db = event.target.result;
                  let transaction = db.transaction(
                    `CreateAttendance${eventID}`,
                    "readwrite"
                  );
                  let objectStore = transaction.objectStore(
                    `CreateAttendance${eventID}`
                  );
                  objectStore.clear();
                };
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      };
    };
  };

  //Syncing Add member Data
  const syncOfflineAddMember = async (memberList) => {
    if (memberList?.createMemberRequests?.length > 0) {
      await axios
        .post(
          `${configData.VENUE_URL}Member/createmember`,
          JSON.stringify(memberList),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          handleCallback(res.data);
          if (
            res.data.statusCode === 200 ||
            (res.data.statusCode === 500 &&
              res.data.errorMessage === "MemberCard Number already exist")
          ) {
            if (offlineCreateAttendanceData.length) {
              syncOfflineMemberCreateAttendance(offlineCreateAttendanceData);
            }
          }
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction("AddMember", "readwrite");
            let objectStore = transaction.objectStore("AddMember");
            objectStore.clear();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Syncing Add expense Data
  const syncOfflineAddExpense = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        await axios
          .post(`${configData.VENUE_URL}Events/addexpense`, JSON.stringify(e), {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            handleCallback(res.data);
            if (res.data.statusCode === 200 && i === memberList.length - 1) {
              let request = window.indexedDB.open("MyDatabase", 1);
              request.onsuccess = (event) => {
                let db = event.target.result;
                let transaction = db.transaction(
                  `AddExpenditure${eventID}`,
                  "readwrite"
                );
                let objectStore = transaction.objectStore(
                  `AddExpenditure${eventID}`
                );
                objectStore.clear();
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  //Syncing delete attendance
  const syncOfflineDeleteAttendance = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        axios
          .delete(
            `${configData.BOOKINGAPI_URL}Members/DeleteAttendences/${e.attendanceID}`,
            {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            handleCallback(res.data);
            if (res.data.result && i === memberList.length - 1) {
              let request = window.indexedDB.open("MyDatabase", 1);
              request.onsuccess = (event) => {
                let db = event.target.result;
                let transaction = db.transaction(
                  `DeleteAttendance${eventID}`,
                  "readwrite"
                );
                let objectStore = transaction.objectStore(
                  `DeleteAttendance${eventID}`
                );
                objectStore.clear();
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  //Syncing delete expense Data
  const syncOfflineDeleteExpense = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        axios
          .delete(
            `${configData.VENUE_URL}Events/deleteexpense/${e.expenseId}/${eventID}`,
            {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            handleCallback(res.data);
            if (
              (res.data.statusCode === 200 ||
                res.data.statusCode === 201 ||
                res.data.statusCode === 202) &&
              i === memberList.length - 1
            ) {
              let request = window.indexedDB.open("MyDatabase", 1);
              request.onsuccess = (event) => {
                let db = event.target.result;
                let transaction = db.transaction(
                  `DeleteExpenditure${eventID}`,
                  "readwrite"
                );
                let objectStore = transaction.objectStore(
                  `DeleteExpenditure${eventID}`
                );
                objectStore.clear();
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  //Syncing update member Data
  const syncOfflineUpdateMemberData = async (memberList) => {
    if (memberList?.length > 0) {
      memberList.map(async (e, i) => {
        await axios
          .post(
            `${configData.VENUE_URL}Member/${
              e?.isAnonymous
                ? "UpdateAnonymousMember"
                : "UpdateMembershipDetails"
            }`,
            JSON.stringify(e),
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            handleCallback(res.data);
            if (res.data.statusCode === 200 && i === memberList.length - 1) {
              let request = window.indexedDB.open("MyDatabase", 1);
              request.onsuccess = (event) => {
                let db = event.target.result;
                let transaction = db.transaction(`UpdateMember`, "readwrite");
                let objectStore = transaction.objectStore(`UpdateMember`);
                objectStore.clear();
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      let request = window.indexedDB.open("MyDatabase", 1);

      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction("AddMember", "readonly");
        let objectStore = transaction.objectStore("AddMember");
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          if (
            event.target.result.length > 0 &&
            event.target.result[0].createMemberRequests?.length > 0
          ) {
            syncOfflineAddMember(event.target.result[0]);
          }
        };
        if (offlineCreateAttendanceData.length > 0) {
          syncOfflineMemberCreateAttendance(offlineCreateAttendanceData);
        }
        if (offlineRecordAttendanceData.length > 0) {
          syncOfflineMemberRecordAttendance(offlineRecordAttendanceData);
        }

        if (offlineUpdateAttendanceData.length > 0) {
          syncOfflineMemberUpdateAttendance(offlineUpdateAttendanceData);
        }
        if (
          offlineUpdateAddExpenditureData.length > 0 &&
          singleCallFLag === 0
        ) {
          singleCallFLag = 1;
          syncOfflineAddExpense(offlineUpdateAddExpenditureData);
        }
        if (offlineUpdateMemberData.length > 0) {
          syncOfflineUpdateMemberData(offlineUpdateMemberData);
        }
        if (deleteAttendanceData.length > 0) {
          syncOfflineDeleteAttendance(deleteAttendanceData);
        }
        if (offlineDeleteExpenseData.length > 0) {
          syncOfflineDeleteExpense(offlineDeleteExpenseData);
        }
      };

      setTimeout(() => {
        setSync(false);
      }, 3000);
    }

    setTimeout(() => {
      offlineDataCallback(false);
      addMemberSyncDataCallback(false);
    }, 10000);
  }, []);

  return <div></div>;
};

export default SyncRecordAttendanceData;
