import { Html5Qrcode } from "html5-qrcode";
import styles from "./QrCode.module.css";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

const qrConfig = { fps: 10, qrbox: { width: 200, height: 200 } };

let html5QrCode;

const QrCodeScanner = ({ setQrCodeMemberId, t, recordAttendence }) => {
  const [, setResult] = useState([]);
  //const [memberId, setMemberId] = useState("");
  const [errorText, setErrorText] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
  }, []);

  useEffect(() => {
    if (!recordAttendence) {
      handleStop();
    }
  }, [recordAttendence]);

  const decodeFileBases64 = (decodedText) => {
    return decodeURIComponent(
      atob(decodedText)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const handleClickAdvanced = () => {
    setIsOpen(true);
    let timer;
    setResult("");
    setErrorText("");

    const qrCodeSuccessCallback = (decodeText, decodedResult) => {
      console.log(decodeText);
      console.log(decodedResult);
      setResult(decodeText);
      try {
        const decodedValue = decodeFileBases64(decodeText);
        setQrCodeMemberId(JSON.parse(decodedValue).MemberID);
      } catch (err) {
        console.log(err);
        setErrorText("Invalid Qr");
      }
      handleStop();
      clearTimeout(timer);
    };

    html5QrCode.start(
      { facingMode: "environment" },
      qrConfig,
      qrCodeSuccessCallback
    );

    timer = setTimeout(() => {
      handleStop();
      setErrorText("Try Again");
    }, 30000);
  };

  const handleStop = () => {
    setIsOpen(false);
    try {
      html5QrCode
        .stop()
        .then(() => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div id="reader" className={styles.camera}></div>
      <Box className={styles.container}>
        {!isOpen ? (
          <Button Button onClick={() => handleClickAdvanced()}>
            {t("Scan QR")}
          </Button>
        ) : (
          <Button onClick={() => handleStop()}>
            {isOpen ? `${t("Stop")}` : null}
          </Button>
        )}
      </Box>
      {errorText ? (
        <Typography variant="paragraph">{errorText}</Typography>
      ) : null}
    </>
  );
};

export default QrCodeScanner;
