import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import NewEventListing from "./NewEventListing";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../../App.css";
import CreateEventDrawer from "./CreateEventDrawer";
import { useTranslation } from "react-i18next";
import configData from "../../config.json";

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    height: 38px;
  }
`;

const CreateEvent = ({ handleModal, toggle, mode, search }) => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [eventList, setEventList] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const token = localStorage.getItem("token");
  const [venue, setVenue] = useState([]);
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [filterVenue, setFilterVenue] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [initialEventList, setInitialEventList] = useState([]);
  const [, setClearFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const territoryNumber = localStorage.getItem("territoryNumber");

  useEffect(() => {
    axios
      .get(`${configData.VENUE_URL}Events/geteventlist/${territoryNumber}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.payload === null) {
          setEventList([]);
        } else {
          setEventList(res?.data?.payload);
          setInitialEventList(res?.data?.payload);
        }
        if (eventList !== null) {
          const venue = Array.from(
            new Set(
              res.data.payload &&
                res.data.payload.map(({ venueKnownAs }) => venueKnownAs)
            )
          );
          const type = Array.from(
            new Set(
              res.data.payload &&
                res.data.payload.map(({ eventTypeName }) => eventTypeName)
            )
          );
          const status = Array.from(
            new Set(
              res.data.payload &&
                res.data.payload.map(({ eventStatusName }) => eventStatusName)
            )
          );
          setVenue(venue);
          setType(type);
          setStatus(status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [openDrawer]);

  const isDateRangeValid = (date) => {
    return date[0] === null || date[1] === null;
  };

  const isDataRowWithinDateRange = (date) => {
    const eventDate = Date.parse(date?.split("T")[0]);
    let endDate;
    if (dateRange[1]) {
      let now_utc_endDate = new Date(
        dateRange[1].getTime() - dateRange[1].getTimezoneOffset() * 60000
      ).toISOString();
      endDate = Date.parse(now_utc_endDate);
    }
    if (eventDate >= startDate && eventDate <= endDate) {
      console.log("filter applied");
      return true;
    } else return false;
  };

  const isTimeRangeValid = (startTime, endTime) => {
    return startTime === null || endTime === null;
  };

  const isTimeRowWithinTimeRange = (date) => {
    const eventTime = new Date();
    const eventTimeHours = date.split("T")[1];
    eventTime.setHours(eventTimeHours.split(":")[0]);
    eventTime.setMinutes(eventTimeHours.split(":")[1]);
    console.log("EventTime", eventTime);
    let now_utc_startTime;
    console.log(startTime);
    let newStartTime;
    let newEndTime;
    if (startTime) {
      now_utc_startTime = new Date(
        startTime.getTime() - startTime.getTimezoneOffset() * 60000
      ).toISOString();
      console.log("Utc Start Time", now_utc_startTime);
      newStartTime = new Date();
      const startTimeHours = now_utc_startTime.split("T")[1];
      newStartTime.setHours(startTimeHours.split(":")[0]);
      newStartTime.setMinutes(startTimeHours.split(":")[1]);
    }
    if (endTime) {
      let now_utc_endTime = new Date(
        endTime.getTime() - endTime.getTimezoneOffset() * 60000
      ).toISOString();
      newEndTime = new Date();
      const endTimeHours = now_utc_endTime.split("T")[1];
      newEndTime.setHours(endTimeHours.split(":")[0]);
      newEndTime.setMinutes(endTimeHours.split(":")[1]);
    }
    if (eventTime >= newStartTime && eventTime <= newEndTime) {
      return true;
    } else return false;
  };

  useEffect(() => {
    let dataSet = initialEventList.filter((item) => {
      isTimeRowWithinTimeRange(item?.eventStart);
      if (
        (!filterVenue || filterVenue === item?.venueKnownAs) &&
        (!filterType || filterType === item?.eventTypeName) &&
        (!filterStatus || filterStatus === item?.eventStatusName) &&
        (isDateRangeValid(dateRange) ||
          isDataRowWithinDateRange(item?.eventStart)) &&
        (isTimeRangeValid(startTime, endTime) ||
          isTimeRowWithinTimeRange(item?.eventStart))
      ) {
        return true;
      }
      return false;
    });
    setEventList(dataSet);
  }, [filterVenue, filterType, filterStatus, dateRange, startTime, endTime]);

  useEffect(() => {
    if (!search) {
      setFilterFn({
        fn: (items) => {
          return items;
        },
      });
    } else {
      const delay = setTimeout(() => {
        if (search) {
          setFilterFn({
            fn: (items) => {
              if (search === "") return items;
              else
                return items.filter((item) =>
                  item.eventName.toLowerCase().includes(search)
                );
            },
          });
        }
      }, 2000);

      return () => clearTimeout(delay);
    }
  }, [search]);

  const handleChange = (e) => {
    if (e?.target?.name === "selectVenue") {
      setFilterVenue(e?.target?.value);
    }
    if (e?.target?.name === "selectType") {
      setFilterType(e?.target?.value);
    }
    if (e?.target?.name === "selectStatus") {
      setFilterStatus(e?.target?.value);
    }
  };

  const handleFilter = () => {
    setEventList(initialEventList);
    setClearFilter(true);
    setFilterStatus("");
    setFilterType("");
    setFilterVenue("");
    setDateRange([null, null]);
    setStartTime(null);
    setEndTime(null);
  };

  return (
    <div>
      <Box>
        <Box sx={{ ml: "2rem", mt: "1rem" }}>
          <h4>{t("EVENTS")}</h4>
        </Box>
        <Box sx={{ ml: "2rem", mt: "1rem", display: "flex", gap: "1rem" }}>
          <Styles>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{
                  fontSize: "16px",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "1px solid #cbc0c0",
                }}
              >
                &nbsp;&nbsp;{t("Date")}&nbsp;-&nbsp;
                <DatePicker
                  className="DatePickerStyle"
                  placeholderText={t("From Date - To Date")}
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    console.log(update);
                    setDateRange(update);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 0,
                  border: "1px solid #cbc0c0",
                  borderRadius: "9px",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "grey",
                }}
              >
                &nbsp;&nbsp;{t("Time")}&nbsp;-&nbsp;
                <DatePicker
                  className="TimePicker"
                  placeholderText={t("Start time")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                />
                <DatePicker
                  className="TimePicker"
                  placeholderText={t("End time")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  selected={endTime}
                  minTime={startTime ? startTime : new Date().setHours(23)}
                  maxTime={new Date().setHours(23)}
                  onChange={(date) => {
                    setEndTime(date);
                  }}
                />
              </div>
            </div>
          </Styles>
          <FormControl
            sx={{ minWidth: "12%", mb: "1rem", borderRadius: "9px" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label">{t("Venue")}</InputLabel>
            <Select
              sx={{ borderRadius: "9px" }}
              labelId="demo-simple-select-label"
              name="selectVenue"
              label="venue"
              value={filterVenue}
              onChange={handleChange}
            >
              {venue.map((venue) => (
                <MenuItem value={venue}>{venue}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: "11%", mb: "1rem" }} size="small">
            <InputLabel id="demo-simple-select-label">{t("Type")}</InputLabel>
            <Select
              sx={{ borderRadius: "9px" }}
              labelId="demo-simple-select-label"
              name="selectType"
              label="type"
              value={filterType}
              onChange={handleChange}
            >
              {type.map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: "14%", mb: "1rem" }} size="small">
            <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>
            <Select
              sx={{ borderRadius: "9px" }}
              labelId="demo-simple-select-label"
              name="selectStatus"
              label="status"
              value={filterStatus}
              onChange={handleChange}
            >
              {status.map((status) => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            size="small"
            style={{
              padding: "0.5rem",
              fontSize: "0.9rem",
              color: "Red",
              textTransform: "none",
              fontWeight: "bold",
              height: "fit-content",
            }}
            onClick={handleFilter}
          >
            {t("Clear")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#00B4FF",
              color: "white",
              height: "2.6rem",
              padding: "0.5rem 0.5rem",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#00B4FF",
              },
            }}
            variant="contained"
            onClick={() => setOpenDrawer(true)}
          >
            {t("Create Event")}
          </Button>
        </Box>
        {openDrawer ? (
          <CreateEventDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            t={t}
          />
        ) : null}

        <NewEventListing
          eventList={eventList}
          filterFn={filterFn}
          handleModal={handleModal}
          t={t}
          mode={mode}
          toggle={toggle}
        />
      </Box>
    </div>
  );
};

export default CreateEvent;
