import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    fetch(
      "https://ceroc-devvenueapi.kellton.net/v1/Application/getappinfo/VenueApp1"
    )
      .then((response) => response.json())
      .then((data) => {
        setLogo(data.payload.appLogo);
      });
  }, []);

  const ChangePassword = (e) => {
    e.preventDefault();

    axios
      .get(`https://ceroc-devapi.kellton.net/v1/User/${email}/pwd/reset`)
      .then(() => {
        navigate("/mailSent");
      })
      .catch((err) => {
        console.log(err);
        setStatus(false);
      });
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setStatus(true);
  };
  return (
    <Box
      sx={{
        display: "flex",
        borderTop: 10,
        borderTopColor: "#F87B03",
        height: "100vh",
        width: "100%",
      }}
    >
      <img className="fr-ps-main-div" src={logo} alt="logo" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60%",
        }}
      >
        <img
          style={{ width: "60%" }}
          src="./Images/ForgotPassword.png"
          alt="forgotPassword Image"
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "lightGrey",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
          gap: 1,
          p: 5,
          flex: 1,
        }}
      >
        <p style={{ fontSize: "24px", fontWeight: 500 }}>
          {t("FORGOT PASSWORD?")}
        </p>
        <p style={{ color: "#99948b", width: 350 }}>
          {t(
            "Please enter the email address you'd like your password reset information sent to"
          )}
        </p>
        <form onSubmit={ChangePassword}>
          <TextField
            sx={{ width: "100%" }}
            type="email"
            value={email}
            onChange={handleChange}
            id="filled-basic"
            label="Email"
            variant="filled"
          />
          {!status && (
            <p style={{ fontSize: "14px", color: "red" }}>
              {t("Entered email doesn't exist")}
            </p>
          )}
          <Button
            sx={{
              mt: 1,
              p: 1,
              width: "100%",
              backgroundColor: "#F87B03",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#F87B03",
              },
            }}
            variant="contained"
            type="submit"
          >
            {t("Request Link")}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
